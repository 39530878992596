import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FaCheck, FaPhone, FaClock, FaMapMarker } from "react-icons/fa"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import SEO from '../components/SEO'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "photo1.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      photo2: file(relativePath: { eq: "photo2.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      photo3: file(relativePath: { eq: "photo3.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title={"Inicio"}
      />
      <section
        id="showcase"
        className="d-flex justify-content-center align-items-center text-white"
      >
        <Container className="text-center">
          <h1 className="display-2 mt-5 mb-5">Salud para una vida mejor</h1>
          <p className="display-5 mb-5 subslogan">
            Inculcar la salud dental como un estilo de vida
          </p>

          <a
            href="tel:+52-954-582-0591"
            className="btn button-primary btn-lg"
            size="lg"
            onClick={e => {
              // Lets track that custom click
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Call Now",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 1 
              })
              //... Other logic here
            }}
          >
            LLAMA AHORA
          </a>
        </Container>
      </section>

      <section>
        <Container className="py-4" id="services">
          <h2 className="mt-4">Servicios</h2>
          <Row className="ml-5 mt-3">
            <h5>En Costa Dental nos preocupamos por tu bienestar, y tenemos para ti los siguientes servicios:</h5>
          </Row>
          <Row className="mt-3">
            <Col md={{ offset: 1 }}>
              <h5 className="py-2">
                <FaCheck className="mr-2 mb-1 primary" size={20} />
                Tratamientos endodónticos
              </h5>
              <h5 className="py-2">
                <FaCheck className="mr-2 mb-1 primary" size={20} />
                Diagnóstico y Tratamiento de la Enfermedad Periodontal
              </h5>
              <h5 className="py-2">
                <FaCheck className="mr-2 mb-1 primary" size={20} />
                Blanqueamientos dentales en una sola cita
              </h5>
            </Col>
            <Col>
              <h5 className="py-2">
                <FaCheck className="mr-2 mb-1 primary" size={20} />
                Odontología General
              </h5>
              <h5 className="py-2">
                <FaCheck className="mr-2 mb-1 primary" size={20} />
                Atención a Adultos y Niños
              </h5>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Img fluid={data.photo1.childImageSharp.fluid} />
            </Col>
            <Col>
              <Img fluid={data.photo2.childImageSharp.fluid} />
            </Col>
            <Col>
              <Img fluid={data.photo3.childImageSharp.fluid} />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container id="howtoget">
          
          <hr className="" />
          <h2 className="mt-5">Como llegar</h2>
          <Row className="py-3 mb-5">
            <Col md={(7, { offset: 1 })}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3837.7898830386494!2d-97.07449674919454!3d15.867636948968729!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85b8f702e4542329%3A0xa21f99c849620268!2sCosta+Dental!5e0!3m2!1sen!2smx!4v1549169359227"
                width="500"
                height="400"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </Col>
            <Col md={5}>
              <h5 className="py-1 mt-4">
                <FaMapMarker className="mr-2 mb-1 primary" size={20} />
                Dirección
              </h5>
              <p>Tercera Poniente 604, Puerto Escondido, Oaxaca 71980</p>
              <a
                href="https://www.google.com/maps/dir//Costa+Dental/data=!4m8!4m7!1m0!1m5!1m1!1s0x85b8f702e4542329:0xa21f99c849620268!2m2!1d-97.0723027!2d15.8676318"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cómo llegar
              </a>
              <h5 className="mt-4">
                <FaPhone className="mr-2 mb-1 primary" size={20} />
                Contacto
              </h5>
              <a href="tel:+52-954-582-0591" onClick={e => {
              // Lets track that custom click
              trackCustomEvent({
                // string - required - The object that was interacted with (e.g.video)
                category: "Call Now",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 2 
              })
              //... Other logic here
            }}
              >+52 954 582 0591</a>
              <h5 className="mt-4">
                <FaClock className="mr-2 mb-1 primary" size={20} />
                Horario de atención
              </h5>
              <p>Lunes a Viernes, de 8:00 a 19:00</p>
              <p>Sábado, 9:00 a 14:00</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
